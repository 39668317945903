import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`Today is our free class at 9:00 & 10:00am so invite a friend!
 Partner/Team ****`}</strong>{`wod today.***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start April 8th.  Contact Eric at`}</em></strong>{`
`}<strong parentName="p"><em parentName="strong">{`fallscitystrength\\@gmail.com for more info.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      